import React, { useEffect } from "react";
import './App.css';

function App() {
  useEffect(() => {
    const event = new Event("reactRendered");
    window.dispatchEvent(event);
  }, []);

  const mailto = () => {
    const email = "renington.neri@p2ptech.dev";
    const subject = "P2P Tech contact request";
    const body = "P2P Tech Team, I would like to know more about your services.";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    window.location.href = mailtoLink;
  }

  return (
    <>
    <div className="wrapper">
      <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top">
        <div className="container container-s">
          <a className="navbar-brand" href="#">P2Ptech.</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto navbar-right">
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#products">Keys</a></li>
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#features">Tech Team</a></li>
              <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#bonus">Advantages</a></li>
              <li className="nav-item"><a className="btn-cta nav-link js-scroll-trigger" href="#signup">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>

      <div id="main" className="main">
        <div className="home">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="hero-img wow fadeIn">
                  <img className="img-fluid" src="assets/images/big.png" alt="Home" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="hero-content wow fadeIn">
                  <h1>Grow your business faster with a flexible tech team</h1>
                  <p>Unleash Innovation with Our Expert Developers and Technology Services.</p>
                  <a className="btn-action js-scroll-trigger" href="#signup">Contact now</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="products" className="features wow fadeInDown">
          <div className="container-m">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="features-intro">
                  <h2>Welcome to P2PTech, where technology matters.</h2>
                  <p>Unlocking Growth Opportunities</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="feature-list">
                  <div className="card-icon">
                    <div className="card-img">
                      <img src="assets/icons/a1.png" width="35" alt="Feature" />
                    </div>
                  </div>
                  <div className="card-text">
                    <h3>Increase Efficiency</h3>
                    <p>Streamline your operations and increase productivity with tailored software solutions.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="feature-list">
                  <div className="card-icon">
                    <div className="card-img">
                      <img src="assets/icons/a2.png" width="35" alt="Feature" />
                    </div>
                  </div>
                  <div className="card-text">
                    <h3>Drive Innovation</h3>
                    <p>Using agile methods, our team focuses on incremental deliveries and continuous improvements, ensuring high product quality and promoting constant innovation.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="feature-list">
                  <div className="card-icon">
                    <div className="card-img">
                      <img src="assets/icons/a3.png" width="35" alt="Feature" />
                    </div>
                  </div>
                  <div className="card-text">
                    <h3>Scalability</h3>
                    <p>With an agile, on-demand team, you optimize resources and reduce operational costs, accelerating time to market without compromising quality.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="features" className="flex-split">
           <div className="container-s">
             <div className="flex-intro align-center wow fadeIn">
               <h2>Tech Team as a Service</h2>
               <p>Tech team as a Service is a flexible, project-based solution that brings together a dedicated team of technology experts to rapidly address your specific business challenges. This service is designed to serve businesses looking to innovate or enhance their tech capabilities without the overhead of recruiting, training, and maintaining a full-time development team.</p>
             </div>
             <div className="flex-inner align-center">
                 <div className="f-image wow">
                   <img className="img-fluid" src="assets/images/team1.jpg" alt="Feature" />
                 </div>
                 <div className="f-text">
                   <div className="left-content">
                     <h2>Flexible.</h2>
                     <p>The team can quickly adapt to changing project requirements, scaling up or down as necessary, which ensures that you only pay for the expertise you need, when you need it.</p>
                   </div>
                 </div>
             </div>

             <div className="flex-inner flex-inverted align-center">
               <div className="f-image f-image-inverted">
                 <img className="img-fluid" src="assets/images/team2.jpg" alt="Feature" />
               </div>
               <div className="f-text">
                 <div className="left-content">
                   <h2>Remote.</h2>
                   <p>This model not only accelerates the development process but also significantly reduces risk and investment, making it an ideal solution for companies aiming to stay agile and competitive in a fast-paced market.</p>
                 </div>
               </div>
             </div>
           </div>
         </div>

         <div className="yd-stats wow fadeIn">
           <div className="container-s">
             <div className="row text-center">
               <div className="col-sm-12">
                 <div className="intro">
                   <h2>Boost efficiency in your business immediately</h2>
                   <p>have an efficient, flexible and tailored team.</p>
                 </div>
               </div>
               <div className="col-sm-4">
                 <div className="counter-up">
                    <h3><span className="counter">13</span>%</h3>
                   <div className="counter-text">
                     <h2>A Stanford University study revealed that remote workers are more productive than their office colleagues.</h2>
                   </div>
                 </div>
               </div>
               <div className="col-sm-4">
                 <div className="counter-up">
                    <h3>$<span className="counter">11,000</span></h3>
                   <div className="counter-text">
                     <h2>For a company with 100 employees working remotely half the time, this represents an annual savings of $1.1 million.</h2>
                   </div>
                 </div>
               </div>
               <div className="col-sm-4">
                 <div className="counter-up">
                    <h3><span className="counter">21</span>%</h3>
                   <div className="counter-text">
                     <h2>A company with 100 employees and a low turnover rate of 10% can save approximately $250,000 per year.</h2>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>

        <div id="bonus" className="ar-ft-single wow fadeIn">
          <div className="container-m">
            <div className="ar-feature">
              <div className="ar-list">
                <ul>
                  <li>
                    <div className="ar-icon">
                      <img src="assets/icons/f1.png" width="30" alt="Icon" />
                    </div>
                    <div className="ar-text">
                      <h3>Rapid and Incremental Deliveries</h3>
                      <p>Agile techniques enable frequent and incremental deliveries, ensuring the product evolves continuously and responds quickly to market changes.</p>
                    </div>
                  </li>
                  <li>
                    <div className="ar-icon">
                      <img src="assets/icons/f4.png" width="30" alt="Icon" />
                    </div>
                    <div className="ar-text">
                      <h3>Enhanced Collaboration and Transparency</h3>
                      <p>Agile methodologies encourage constant communication and collaboration among cross-functional teams, increasing transparency and aligning everyone involved with the project's goals.</p>
                    </div>
                  </li>
                  <li>
                    <div className="ar-icon">
                      <img src="assets/icons/f11.png" width="30" alt="Icon" />
                    </div>
                    <div className="ar-text">
                      <h3>Continuous Improvement and Constant Feedback</h3>
                      <p>With short development cycles and regular reviews, agile techniques promote continuous improvement and the constant incorporation of feedback from users and stakeholders.</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="ar-image">
                <img className="ar-img img-fluid" src="assets/images/agile.jpg" alt="Hero Image" />
              </div>
            </div>
          </div>
        </div>

        <div id="signup" className="cta-sm">
          <div className="container-m text-center">
            <div className="cta-content">
               <h4>LET'S TALK ABOUT IT</h4>
               <div className="form wow fadeIn" data-wow-delay="0.2s">
                <input className="submit-button" type="submit" value="CONTACT ME" onClick={mailto} />
                <div id="response"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-sm">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <a className="footer-logo" href="#">P2P Tech.</a>
              </div>
              <div className="col-md-4">
                <h6>&copy; P2PTech 2024 Rights Reserved</h6>
              </div>
              <div className="col-md-4">
                <ul>
                  <li><a href="https://github.com/renington" target="_blank" rel="noreferrer">Github</a></li>
                  <li><a href="https://www.linkedin.com/in/renington" target="_blank" rel="noreferrer">Linkedin</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    </>
  );
}

export default App;
